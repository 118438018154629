import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./seguros-rapidos.scss"

export default function SegurosRapidos() {
  return (
    <div className="seguros-rapidos-wrapper">
      <StaticImage
        src={"../../../assets/images/seguros-rapidos-bg.jpg"}
        alt="Seguros Rápidos - Uniserse Costa Rica"
        style={{
          gridArea: "1/1",
        }}
        layout="fullWidth"
        loading="eager" />
      <div className="slide-content">
        <div>
          <h2>Pronto podrás comprar tus seguros en línea</h2>
        </div>
      </div>
    </div>
  )
}
