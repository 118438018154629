import React from 'react'
import _ from 'lodash'
import { useStaticQuery, Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import './aseguradoras.scss'

export default function Aseguradoras() {
  const data = useStaticQuery(graphql`
    query AllAseguradoras {
      allAseguradoras: allWpAseguradora {
        aseguradoras: nodes {
          title
          id
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 160
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="blk blk-aseguradoras" id="proveedores">
      <h2>Aseguradoras que trabajan con nosotros</h2>
      <ul>
        {_.map(data.allAseguradoras.aseguradoras, (aseguradora) => {
          const { id, title, featuredImage: img, slug } = aseguradora
          const imgSrc = getImage(img.node.localFile)
          return (
            <li key={id}>
              <Link to={`aseguradora/${slug}`}>
                <GatsbyImage
                  image={imgSrc}
                  alt={title}
                />
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
