import React from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import "./acerca.scss"

export default function Acerca() {
  return (
    <div className="blk blk-acerca">
      <h3>Acerca de Nosotros</h3>
      <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
        {({ isVisible }) => (
          <ul>
            <li>
              <span className="number">{isVisible && <CountUp end={50} duration={2.75} />}+</span>
              <h4>Años de experiencia</h4>
            </li>
            <li>
              <span className="number">{isVisible && <CountUp end={20000} duration={2.75} separator="," />}</span>
              <h4>Gestiones pólizas de seguros</h4>
            </li>
            <li>
              <span className="number">{isVisible && <CountUp end={10000} duration={2.75} separator="," />}</span>
              <h4>Reclamos atendidos al año</h4>
            </li>
            <li>
              <span className="number">{isVisible && <CountUp end={35000} duration={2.75} separator="," />}</span>
              <h4>Clientes asegurados</h4>
            </li>
            <li>
              <span className="number">{isVisible && <CountUp end={90} duration={2.75} />}%</span>
              <h4>NPS Cierre 2020</h4>
            </li>
          </ul>
        )}
      </VisibilitySensor>
    </div>
  )
}
