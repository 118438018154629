import React, { useState } from 'react';
import Modal from 'react-modal';

import FormModal from './FormModal'
import { AiFillCloseCircle as IcoClose, AiOutlineInfoCircle as IcoInfo } from "react-icons/ai";


export default function ModalServicio({ seguro }) {
  const [modalIsOpen, setIsOpen] = useState(false);
  
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '30px 60px'
    },
  };

  if (!modalIsOpen) {
    return (
      <button className="btn" onClick={() => setIsOpen(true)}>Más Información <IcoInfo /></button>
      )
    }
    
  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel={`Información ${seguro}`}
    >
      <div className="modal-head">
        <h1>Formulario de Información para Seguros</h1>
        <IcoClose className="btn-close" onClick={() => setIsOpen(false)}/>
      </div>
      <div className="modal-form">
        <FormModal seguro={seguro} setIsOpen={setIsOpen} />
      </div>
    </Modal>
  )
}
