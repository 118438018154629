import React from "react"
import ScrollToTop from "react-scroll-up"
import {
  AiFillUpCircle as IcoUp,
  AiOutlineWhatsApp as IcoWapp,
} from "react-icons/ai"

import "./scroll-up.scss"

export default function index() {
  return (
    <>
      <ScrollToTop showUnder={160} style={{ bottom: 180 }}>
        <a
          href="https://api.whatsapp.com/send?phone=50686473773&text=Gracias%20por%20contactar%20a%20Uniserse%2C%20le%20redireccionamos%20a%20nuestro%20nuevo%20n%C3%BAmero...%20%F0%9F%93%B2"
          target="_blank"
          rel="noreferrer"
          alt="WhatsApp"
        >
          <span className="whatsapp">
            <IcoWapp />
          </span>
        </a>
      </ScrollToTop>

      <ScrollToTop showUnder={160} style={{ bottom: 120, zIndex: 999 }}>
        <span className="scroll-up">
          <IcoUp />
        </span>
      </ScrollToTop>
    </>
  )
}
