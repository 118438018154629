import React, { useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";

import spinner from '../../../assets/images/spinner.svg';
import { AiOutlineCheckCircle as IcoCheck } from "react-icons/ai";


const validationSchema = Yup.object({
  nombre: Yup.string().required("Ingrese su nombre."),
  email: Yup.string().email("Formato incorrecto de email").required("Ingrese su email."),
  asunto: Yup.string().required("El campo de asunto es requerido."),
  mensaje: Yup.string().required("El campo de mensaje es requerido.")
});

const initialValues = {
  nombre: "",
  email: "",
  asunto: "",
  mensaje: "",
}

export default function FormContact() {
  const [msg, setMsg] = useState("Ha ocurrido un error. Favor intente más tarde.")
  const [err, setErr] = useState(false)
  const [success, setSuccess] = useState(false)
  
  const [loader, setLoader] = useState(false)

  const handleSubmit = async (values, actions) => {
    const endpoint = process.env.FUNCTIONS_ENDPOINT
    setLoader(true)   
    try {
      const response = await axios.post(`${endpoint}/contact`, values);
      actions.resetForm()
      setMsg(response.data.msg);
      setLoader(false)
      setSuccess(true)
      setTimeout(() => setSuccess(false), 4500);
    } catch (error) {
      setErr(true)
      setMsg("Ha ocurrido un error. Favor intente más tarde.")
    }
  }

  if (loader) {
    return (
      <div style={{ textAlign: "center" }} className="fixed-height-container">
        <h2>Enviando formulario...</h2>
        <img width={60} src={spinner} alt="Cargando" />
      </div>
    )
  }
  
  if (success) {
    return (
      <div style={{ textAlign: "center" }} className="fixed-height-container">
        <h1 style={{ display: "flex", color: "darkcyan" }}><IcoCheck />Su mensaje ha sido recibido.</h1>
        <h2>Hemos enviado una copia a tu email.</h2>
        <h3>{msg}</h3>
        <p>Muy pronto estaremos en contacto.</p>
      </div>
    )
  }

  if (err) return (<div className="err">{msg}</div>)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ handleSubmit, handleChange, handleBlur, isSubmitting, touched, values, errors }) => (
        <form onSubmit={handleSubmit} className="fixed-height-container">
          <div className="field">
            <label htmlFor="nombre">
              Nombre completo:
              <input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.nombre}
                id="nombre"
                name="nombre"
              />
            </label>
            <div className="err">{errors.nombre && touched.nombre && errors.nombre}</div>
          </div>

          <div className="field">
            <label htmlFor="email">
              Email:
              <input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                id="email"
                name="email"
              />
            </label>
            <div className="err">{errors.email && touched.email && errors.email}</div>
          </div>

          <div className="field">
            <label htmlFor="asunto">
              Asunto:
              <input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.asunto}
                id="asunto"
                name="asunto"
              />
            </label>
            <div className="err">{errors.asunto && touched.asunto && errors.asunto}</div>
          </div>

          <div className="field">
            <label htmlFor="mensaje">
              Mensaje:
              <textarea
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mensaje}
                id="mensaje"
                name="mensaje"
              />
            </label>
            <div className="err">{errors.mensaje && touched.mensaje && errors.mensaje}</div>
          </div>
          <button type="submit" className="btn" disabled={isSubmitting}>Enviar</button>
        </form>
      )}
    </Formik>
  );
}
