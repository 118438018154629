import React from 'react'
import _ from "lodash"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick";

import "./comentarios.scss"

// Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Comentarios() {
  const data = useStaticQuery(graphql`
    query Coments {
      allWpComentario {
        comments: nodes {
          id
          title
          comentariosCustomFields {
            descripcion
            autor
          }
        }
      }
    } 
  `)
  const handleSlides = () => _.map(data.allWpComentario.comments, (comment) => {
    const { id, comentariosCustomFields: { descripcion, autor } } = comment
    return (
      <div key={id}>
        <div className="slide-wrapper">
          <div className="slide-content">
            <p>{descripcion}</p>
            <span className="autor">{autor}</span>
          </div>
        </div>
      </div>
    )
  })
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="blk blk-comentarios">
      <h3>¡Estás en las mejores manos!</h3>
      <h4>Nuestra asesoría es respaldada por miles de clientes y empresas en el país</h4>
      <Slider {...settings}>
        {handleSlides()}
      </Slider>
    </div>
  )
}


