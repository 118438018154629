import React from 'react'
import _ from 'lodash'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import './alizanzas.scss'

export default function Aseguradoras() {
  const data = useStaticQuery(graphql`
    query AllAlianzas {
      allAlianzas: allWpAlianzaInternacional {
        alizanzas: nodes {
          title
          id
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 160
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="blk blk-alizanzas">
      <h2>Alianzas Internacionales</h2>
      <ul>
        {_.map(data.allAlianzas.alizanzas, (alizanza) => {
          const { id, title, featuredImage: img } = alizanza
          const imgSrc = getImage(img.node.localFile)
          return (
            <li key={id}>
              <GatsbyImage
                image={imgSrc}
                alt={title}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
