import React, { useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";

import spinner from '../../../assets/images/spinner.svg';
import { AiOutlineCheckCircle as IcoCheck } from "react-icons/ai";

import "./modal-servicios.scss"

export default function FormModal({ seguro, setIsOpen }) {
  const [msg, setMsg] = useState("Ha ocurrido un error. Favor intente más tarde.")
  const [err, setErr] = useState(false)
  const [success, setSuccess] = useState(false)

  const [loader, setLoader] = useState(false)

  const handleSubmit = async (values, actions) => {
    const endpoint = process.env.FUNCTIONS_ENDPOINT
    setLoader(true)
    try {
      const response = await axios.post(`${endpoint}/infoRequest`, values);
      actions.resetForm()
      setMsg(response.data.msg);
      setLoader(false)
      setSuccess(true)
      setTimeout(() => setSuccess(false), 4500);
      setIsOpen(false)
    } catch (error) {
      setErr(true)
      setMsg("Ha ocurrido un error. Favor intente más tarde.")
    }
  }

  const validationSchema = Yup.object({
    seguro: Yup.string().required(),
    nombre: Yup.string().required("Por favor ingrese su nombre."),
    email: Yup.string().email("Formato incorrecto.").required("Por favor ingrese su email."),
    mensaje: Yup.string()
  });

  const initialValues = {
    seguro,
    nombre: "",
    email: "",
    mensaje: "",
  }

  if (loader) {
    return (
      <div style={{ textAlign: "center" }}>
        <h2>Enviando formulario...</h2>
        <img width={60} src={spinner} alt="Cargando" />
      </div>
    )
  }

  if (success) {
    return (
      <div style={{ textAlign: "center" }}>
        <h1 style={{ display: "flex", color: "darkcyan" }}><IcoCheck />Su mensaje ha sido recibido.</h1>
        <h2>Hemos enviado una copia a tu email.</h2>
        <p>Muy pronto estaremos en contacto.</p>
      </div>
    )
  }

  if (err) return (<div className="err">{msg}</div>)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ handleSubmit, handleChange, isSubmitting, values, errors }) => (
        <form onSubmit={handleSubmit}>

          <div className="field">
            <label htmlFor="nombre">
              Seguro:
            </label>
            <input
              readOnly
              type="text"
              value={values.seguro}
              id="seguro"
              name="seguro"
            />
          </div>

          <div className="field">
            <label htmlFor="nombre">
              Nombre completo:
            </label>
            <input
              type="text"
              onChange={handleChange}
              value={values.nombre}
              id="nombre"
              name="nombre"
            />
            <div className="error">{errors.nombre}</div>
          </div>

          <div className="field">
            <label htmlFor="email">
              Email:
              </label>
            <input
              type="text"
              onChange={handleChange}
              value={values.email}
              id="email"
              name="email"
            />
            <div className="error">{errors.email}</div>
          </div>

          <div className="field">
            <label htmlFor="mensaje">
              Mensaje:
              </label>
            <textarea
              type="text"
              onChange={handleChange}
              value={values.mensaje}
              id="mensaje"
              name="mensaje"
            />
            <div className="error">{errors.mensaje}</div>
          </div>

          {isSubmitting && <img width={60} src={spinner} alt="Redireccionando" />}
          <button type="submit" className="btn" disabled={isSubmitting}>Enviar</button>

        </form>
      )}
    </Formik>
  );
}
