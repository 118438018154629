import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useStaticQuery, graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// comps
import SegurosPersonales from "./SegurosPersonales"
import SegurosEmpresariales from "./SegurosEmpresariales"

import "./seguros.scss"

export default function Seguros({ location }) {
  const search = location.search || null
  const urlParams = new URLSearchParams(search);

  const data = useStaticQuery(graphql`
    query Seguros {
      allWpSeguro {
        allSeguros: nodes {
          id
          slug
          segurosCustomFields {
            tipoDeSeguro
          }
        }
      }
    }
  `)

  const [seguroType, setSeguroType] = useState("Personal")
  const [seguroParams, setSeguroParams] = useState([])
  
  const urlSeguroParam = urlParams.get("seguro")
  const breakpoints = useBreakpoint();
  
  useEffect(() => {
    if (!urlSeguroParam) return null
    const { allSeguros } = data.allWpSeguro
    const findSeguro = _.find(allSeguros, { "slug": urlSeguroParam })
    if (!findSeguro) return null
    const { tipoDeSeguro } = findSeguro.segurosCustomFields
    setSeguroParams([tipoDeSeguro, findSeguro.id])
    return setSeguroType(tipoDeSeguro)
  }, [urlSeguroParam, data])

  const handleTypeOptions = () => {
    return (
      <ul className="tipo-seguro">
        <li>
          <button
            onClick={() => setSeguroType("Personal")}
            className={seguroType === "Personal" && "selected"}
          >Seguros Personales</button>
        </li>
        <li>
          <button
            onClick={() => setSeguroType("Empresarial")}
            className={seguroType === "Empresarial" && "selected"}
          >Seguros Empresariales</button></li>
      </ul>

    )
  }
  const handleContent = () => {
    switch (seguroType) {
      case "Personal":
        return <SegurosPersonales breakpoints={breakpoints} seguroParams={seguroParams}/>
      case "Empresarial":
        return <SegurosEmpresariales breakpoints={breakpoints} seguroParams={seguroParams}/>
      default:
        return null
    }
  }
  return (
    <div className=" blk blk-seguros">
      {handleTypeOptions()}
      {handleContent()}
    </div>
  )
}
