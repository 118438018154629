import React from 'react';
import _ from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
import Slider from "react-slick";

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import './corredores.scss';

// Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

export default function Corredores() {
	const data = useStaticQuery(graphql`
		query Corredores {
			corredores: allWpCorredor(sort: { order: ASC, fields: corredoresCustomFields___ordenamiento }) {
				nodes {
					id
					title
					corredoresCustomFields {
						descripcion
						email
						imagen {
              localFile {
                childImageSharp {
                  gatsbyImageData(
										width: 280
										height: 280
										placeholder: BLURRED
										formats: [AUTO, WEBP, AVIF]
									)
                }
              }
						}
						linkedin
            whatsapp
					}
				}
			}
		}
	`);
	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 6,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 9999999,
				settings: "unslick"
			},
			{
				breakpoint: 720,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: true,
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	const handleContent = () => {
		return (
			_.map(data.corredores.nodes, (corredor) => {
				const { id, title, corredoresCustomFields } = corredor;
				const { descripcion, email, linkedin, imagen, whatsapp } = corredoresCustomFields
				const imgSrc = getImage(imagen.localFile)
				return (
					<div key={id} className="corredor-item">
						<div className="image-container">
							<GatsbyImage
								image={imgSrc}
								alt={title}
							/>
							<ul className="floating-link">
								<li>
									<a
										className="ico linkedin"
										href={linkedin}
										target="_blank"
										without
										rel="noreferrer"
									>
										linkedin
									</a>
								</li>
								<li>
									<a
										className="ico whatsapp"
										href={`https://msng.link/o/?506${whatsapp}=wa`}
										target="_blank"
										without
										rel="noreferrer"
									>
										Mensaje whatsapp
									</a>
								</li>
	
								<li>
									<a className="ico email" href={`mailto:${email}`}>
										Email
								</a>
								</li>
							</ul>
						</div>
						<div className="content">
							<h3>{title}</h3>
							<p>{descripcion}</p>
						</div>
					</div>
				);
			})
		)
	}
	return (
		<div className="blk blk-corredores" id="corredores">
			<h1>Corredores</h1>
			<Slider {...settings}>
				{handleContent()}
			</Slider>
		</div>
	);
}
