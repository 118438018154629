import React, { useState, useEffect } from 'react'
import _ from "lodash"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// comps
import ModalServicio from "../ModalServicio"

export default function SegurosEmpresariales(props) {
  const { breakpoints, seguroParams } = props
  const data = useStaticQuery(graphql`
    query SegurosEmpresariales {
      allWpSeguro(filter: {segurosCustomFields: {tipoDeSeguro: {eq: "Empresarial"}}}) {
        segurosEmpresariales: nodes {
          id
          title
          segurosCustomFields {
            fieldGroupName
            tipoDeSeguro
            nombre
            descripcion
            imagen {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 920
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            icono {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 40
                    placeholder: BLURRED
                  )
                }
              }
            } 
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (_.isEmpty(seguroParams)) return null
    const [seguroType, id] = seguroParams
    if (seguroType === "Empresarial") return setSelected(id)
  }, [seguroParams])

  const seguroDefault = data.allWpSeguro.segurosEmpresariales[0].id
  const [selected, setSelected] = useState(seguroDefault)

  const handleSelect = () => {
    const { segurosEmpresariales } = data.allWpSeguro
    return (
      <select className="custom-select" onBlur={(e) => setSelected(e.target.value)} onChange={(e) => setSelected(e.target.value)}>
        <option value={seguroDefault} defaultValue>Seleccione un Seguro Empresarial</option>
        {_.map(segurosEmpresariales, ({ id, segurosCustomFields: fields }) => {
          return (
            <option key={id} value={id}>{fields.nombre}</option>
          )
        })}
      </select>
    )
  }

  const handleList = () => {
    const { segurosEmpresariales } = data.allWpSeguro
    const listItems = _.map(segurosEmpresariales, ({ id, segurosCustomFields: fields }) => {
      return (
        <li key={id}>
          <button
            onClick={() => setSelected(id)}
            className={selected === id && "selected"}
          >{fields.nombre}
          </button>
        </li>
      )
    })
    return (
      <ul className="seguro-subcategoria">{listItems}</ul>
    )
  }

  const handleContent = () => {
    const current = _.find(data.allWpSeguro.segurosEmpresariales, { id: selected })
    const { segurosCustomFields: fields } = current
    const imgSrc = getImage(fields.imagen.localFile)
    const imgIco = fields.icono && getImage(fields.icono.localFile)
    return (
      <div className="seguros-container">
        <div className="main-img"><GatsbyImage image={imgSrc} alt={fields.nombre} /></div>
        <div className="main-content">
          <h3>{fields.nombre}</h3>
          <div className="content-wrapper">
            {imgIco ? <GatsbyImage image={imgIco} /> : <span />}
            <p>{fields.descripcion}</p>
          </div>
          <ModalServicio seguro={fields.nombre} />
        </div>
      </div>
    )
  }

  return (
    <div className="seguros-empresariales">
      {breakpoints.sm ? handleSelect() : handleList()}
      {handleContent()}
    </div>
  )
}
