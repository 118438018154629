import React from 'react'
import FormContact from './FormContact'

import { getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import './contacto.scss'

export default function Contacto() {
  const data = useStaticQuery(graphql`
    query ContactBg {
      allFile(filter: {relativePath: {eq: "contacto-bg.jpg"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                height: 1200
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }   
  `)
  const imgSrc = getImage(data.allFile.edges[0].node.childImageSharp.gatsbyImageData)

  const bgImage = convertToBgImage(imgSrc)

  return (
    <div className="blk-contacto">
      <div className="wrapper">
        <div className="image-container">
          <BackgroundImage
            Tag="div"
            className="bg-img"
            {...bgImage}
            preserveStackingContext
          />
        </div>
        <div className="form-wrapper" id="contacto">
          <h1>Contacto</h1>
          <ul className="add-info">
            <li className="telefono"><a href="tel:+50625194500">+506 2519-4500</a></li>
            <li className="email"><a href="mailto:info@uniserse.com">info@uniserse.com</a></li>
            <li className="direccion">La Uruca, frente costado S.E. del puente Juan Pablo II San José, Costa Rica.</li>
            <li className="waze"><a href="https://waze.com/ul/hd1u0tgpzc" target="_blank" rel="noreferrer">Ver mapa</a></li>
            <li className="horario">Lunes a Viernes de 8:00a.m. a 5:00p.m.</li>
          </ul>
          <FormContact />
        </div>
      </div>
    </div>
  )
}
