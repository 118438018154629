import React from 'react'
import { Helmet } from "react-helmet"

// comps
import Seguros from "../components/Seguros"
import SegurosRapidos from "../components/SegurosRapidos"
import Corredores from "../components/Corredores"
import Servicios from "../components/Servicios"
import Aseguradoras from "../components/Aseguradoras"
import Alizas from "../components/Alianzas"
import Comentarios from "../components/Comentarios"
import Acerca from "../components/Acerca"
import Contacto from "../components/Contacto"
import ScrollUp from "../components/ScrollUp"

// Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Index({ location }) {
  return (
    <div className="frontpage">
      <Helmet>
        <title>Uniserse Costa Rica - Corredor de seguros</title>
        <meta name="description" content="Intermediamos seguros para todas las aseguradoras que operan en el mercado costarricense, 
        con un enfoque orientado en el servicio al cliente, la innovación y la mejora continúa." />
      </Helmet>
      <div className="container">
        <Seguros location={location} />
      </div>
      <SegurosRapidos />
      <div className="container">
        <Servicios />
        <Corredores />
        <Aseguradoras />
        <Alizas />
        <Comentarios />
        <Acerca />
      </div>
      <Contacto />
      <ScrollUp />
    </div>
  )
}
