import React from 'react'
import _ from 'lodash'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import './servicios.scss'

export default function Corredores() {
  const data = useStaticQuery(graphql`
  query AllServicios {
    allSevicios: allWpServicio {
      servicios: nodes {
        title
        id
        serviciosCustomFields {
          descripcion
          imagen {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 680
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }    
`)
  return (
    <div className="blk blk-servicios" id="servicios">
      <h1>Servicios</h1>
      <ul>
        {_.map(data.allSevicios.servicios, (servicio) => {
          const { id, title, serviciosCustomFields: { imagen, descripcion } } = servicio
          const imgSrc = getImage(imagen.localFile)
          return (
            <li key={id}>
              <GatsbyImage image={imgSrc} alt={title} />
              <div className="content">
                <h3>{title}</h3>
                {descripcion}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
